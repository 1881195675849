import "./App.css";
import Homepage from "./component/Homepage";
import Navbar from "./component/Navbar";

function App() {
  return <div className="App">
    <Navbar />
    <Homepage />
  </div>;
}

export default App;
